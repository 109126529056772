import React from 'react';
import { IconButton } from '@material-ui/core';
import { ReactComponent as Search } from '../../ressources/svgs/search.svg';
import { ReactComponent as Tools } from '../../ressources/svgs/tools.svg';
import { ReactComponent as Hero } from '../../ressources/svgs/hero.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Footer from '../Footer/Footer.js';
import { makeStyles } from '@material-ui/core/styles';

const lpStyles = makeStyles(tools2go => ({
    view: {
        minHeight: '90vh',
        width: '100vw',
        textAlign: 'center',
        marginTop: '-10vh',
        paddingTop: '10vh',
    },
    lpSearchDiv:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#00253E',
        width: '90vw',
        height: '25vh',
        margin: '10vh 5vw 0.75vh 5vw',
        color: tools2go.colors.textPrimary,
    },
    lpMyToolsDiv:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        background: '#00253E',
        float: 'left',
        width: '44vw',
        height: '28vh',
        margin: '0.75vh 1vw 8vh 5vw',
        color: tools2go.colors.textPrimary,
        textAlign: 'center',
    },
    lpSiteDiv:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        background: '#00253E',
        float: 'left',
        width: '44vw',
        height: '28vh',
        margin: '0.75vh 5vw 8vh 1vw',
        color: tools2go.colors.textPrimary,
        textAlign: 'center',
    },
    lpBigButton: {
        display: 'inline-block',
        background: 'linear-gradient(-45deg, #127D8D, #13D8F6)',
        width: '15vh',
        height: '15vh',
        marginLeft: '5vw',
        fill: tools2go.colors.textPrimary,
    },
    lpSmallButton: {
        display: 'block',
        background: 'linear-gradient(-45deg, #127D8D, #13D8F6)',
        width: '10vh',
        height: '10vh',
        margin: '4vh 0vw 0vh 0vw',
        fill: tools2go.colors.textPrimary,
    },
    lpLinkTitle: {
        textDecoration: 'none',
        color: tools2go.colors.textPrimary,
        width: '100%',
        fontSize: '5.5vh',
        fontWeight: '300',
        margin: '0',
        textAlign: 'center',
    },
    lpLinkText: {
        textDecoration: 'none',
        color: tools2go.colors.textPrimary,
        width: '99%',
        fontSize: '3.5vh',
        textAlign: 'center',
        margin: 'auto auto 2vh auto',
    },
    lpSvg: {
        width: '5vh',
        height: '5vh',
    },
    myToolsSvg: {
        width: '5vh',
        height: '5vh',
        marginLeft: '-2vw',
    },
    sitesSvg: {
        width: '10vh',
        height: '10vh',
        marginLeft: '1vw',
    },
}));

const LandingPage = () => {
    const style = lpStyles();
    const { t } = useTranslation();

    return (
        <div className={style.view}>
            <Link to='/search'>
                <div className={style.lpSearchDiv}>
                    <IconButton variant="contained" color="primary" className={style.lpBigButton}>
                        <Search className={style.lpSvg}/>
                    </IconButton>
                    <div>
                        <h3 className={style.lpLinkTitle}>{t('landingPage:search')}</h3>
                        <p className={style.lpLinkText}>{t('landingPage:findTheTool')}</p>
                    </div>
                </div>
            </Link>
            <Link to='/my-tools'>
                <div className={style.lpMyToolsDiv}>
                    <IconButton variant="contained" color="primary" className={style.lpSmallButton}>
                        <Tools className={style.myToolsSvg}/>
                    </IconButton>
                    <div>
                        <p className={style.lpLinkText}>{t('landingPage:myTools')}</p>
                    </div>
                </div>
            </Link>
            <Link to='/sites'>
                <div className={style.lpSiteDiv}>
                    <IconButton variant="contained" color="primary" className={style.lpSmallButton}>
                        <Hero className={style.sitesSvg}/>
                    </IconButton>
                    <div>
                        <p className={style.lpLinkText}>{t('landingPage:sites')}</p>
                    </div>
                </div>
            </Link>
            <Footer />
        </div>
    )
};

export default LandingPage;
