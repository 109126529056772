import React, { useState } from 'react';
import { tableStyle } from '../Theme/Theme.js';
import MaterialTable from 'material-table';
import { Button, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedArticleInStore, getToolDetailFromDB } from '../../redux/actions/toolsActions.js';
import { getAccessTokenFromStore } from '../../redux/selectors/accountSelectors.js';
import { getSearchResult, getSearchError } from '../../redux/selectors/generalSelectors.js';
import { setLastPage, search, setSearchError } from '../../redux/actions/generalActions.js';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Image from "react-graceful-image";
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Hero } from '../../ressources/svgs/hero.svg';

const searchStyles = makeStyles(tools2go => ({
	view: {
		minHeight: '90vh',
		width: '100vw',
		textAlign: 'center',
		marginTop: '-10vh',
		paddingTop: '10vh',
		backgroundColor: 'linear-gradient(#FFFFFF, #ECECEC)',
	},
	dataNotLoadedSvg: {
		width: '80vw',
		margin: '5vh auto 5vh auto',
		fill: '#FFFFFF',
	},
	dataNotLoadedHeader: {
		width: '80vw',
		margin: '5vh auto 5vh auto',
		color: '#FFFFFF',
		fontWeight: '300',
	},
	dataNotLoadedButton: {
		width: '80vw',
		margin: 'auto',
		fontWeight: '700',
	},
	SearchAvatar: {
        textAlign: 'center',
        maxWidth: '10vh',
        width: 'auto',
        maxHeight: '7vh',
        height: 'auto',
        border: '0px',
        borderColor: '#FFFFFF',
		borderRadius: '0',
    },
	missingImage: {
		textAlign: 'center',
        maxWidth: '10vh',
        width: 'auto',
        maxHeight: '7vh',
        height: 'auto',
        border: '0px',
        borderColor: '#FFFFFF',
		borderRadius: '0',
		backgroundColor: '#E4E4E4',
		fill: '#747474',
	},
    searchTitle: {
        color: '#0D344F',
        fontSize: '1rem',
    },
    searchDesc: {
        color: '#878787',
    },
	inStock: {
        background: 'linear-gradient(127deg, #1F6487 0%, #0D344F 82%)',
    },
    outOfStock: {
        background: 'linear-gradient(127deg, #FF0038 0%, #78001C 82%)',
    },
	textField: {
		margin: '0vh auto 2vh auto',
		background: '#FAFAFA',
		boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
		borderRadius: '2px',
	},
}));

const Search = () => {
	const { t } = useTranslation();
	const accessToken = useSelector(getAccessTokenFromStore);
	const dispatch = useDispatch();
	const history = useHistory();
	const style = searchStyles();
	const searchResult = useSelector(getSearchResult);
	const searchError = useSelector(getSearchError);
	const [searchTerm, setSearchTerm] = useState('');
	const [searching, setSearching] = useState(false);

	const loadAgain = () => {
		dispatch(setSearchError(''));
		dispatch(search(accessToken, searchTerm));
	}

	if (searchResult === '') {
		setSearching(true);
	}

    if (searching || searchError !== '') {
        return(
			<div className={style.view}>
				<Hero className={style.dataNotLoadedSvg} />
    			{searchError==='' ? (<h3 className={style.dataNotLoadedHeader}>{t('search:loading')}</h3>) : (<h3 className={style.dataNotLoadedHeader}>{t('search:cannotGetData')}: {searchError.name}</h3>)}
				{searchError==='' ? (<h3 className={style.dataNotLoadedHeader}>{t('search:pleaseWait')}</h3>) : (<Button className={style.dataNotLoadedButton} color='secondary' variant="contained" onClick={loadAgain}>{t('search:tryAgain')}</Button>)}
    		</div>
        );
    }

	const defaultImage = (ev) => {
		ev.target.src = require('../../ressources/svgs/TH_Logo.svg');
		ev.target.className = style.missingImage;
	};

	const handleSearchTermChange = e => {
		setSearchTerm(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		dispatch(search(accessToken, searchTerm));
	}

	return (
		<div className={style.view}>
			<form onSubmit={handleSubmit}>
				<TextField
					className={style.textField}
					name='search'
					label=''
					placeholder={t('search:search')}
					autoComplete="search"
					value={searchTerm}
					onChange={handleSearchTermChange}
					margin='normal'
					variant='outlined'
				/>
			</form>
			<MaterialTable
				title=''
				options={{search: false, paging: false}}
				columns={[
				{ field: 'picture', cellStyle: tableStyle.leftCell, headerStyle: tableStyle.leftCell, render: (rowData) => <Image src={rowData.picture ? rowData.picture : '../../ressources/svgs/TH_Logo.svg'} alt='tool' className={style.SearchAvatar} onError={defaultImage}/>},
				{ field: 'text1', cellStyle: tableStyle.largeCellStyle, headerStyle: tableStyle.largeCellStyle, render: (rowData) =>
					<div>
						<p className={style.searchTitle} >{rowData.text1}</p>
						<p className={style.searchDesc} >{rowData.ordersign}</p>
					</div>
				},
				]}
				data={searchResult}
				onRowClick={(event, rowData) => {
					// dispatch(setSelectedArticleInStore(articles.find(article => {return(article.id === rowData.id)})));
					dispatch(getToolDetailFromDB(accessToken, rowData.id));
                    dispatch(setSelectedArticleInStore(rowData.id));
					dispatch(setLastPage('/search'));
					history.push('/detail-view');
				}}
			/>
		</div>
	)
};

export default Search;
