import React, { useState } from 'react';
import { IconButton, Drawer, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useSelector } from 'react-redux';
import { getLastPage } from '../../redux/selectors/generalSelectors.js';
import Avatar from '@material-ui/core/Avatar';
import { getUsername } from '../../redux/selectors/accountSelectors.js';
import { ReactComponent as Search } from '../../ressources/svgs/search.svg';
import { ReactComponent as Tools } from '../../ressources/svgs/tools.svg';
import { ReactComponent as Hero } from '../../ressources/svgs/hero.svg';
import { ReactComponent as Info } from '../../ressources/svgs/info.svg';
import { ReactComponent as Logout } from '../../ressources/svgs/logout.svg';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { logout } from '../../redux/actions/accountActions';
import { useDispatch } from 'react-redux';

const mainMenuStyles = makeStyles(tools2go => ({
	mainMenu: {
		height: '90vh',
	},
	drawerHeader: {
		backgroundImage: 'linear-gradient(-90deg, #0D344F, #1F6487)',
		padding: '3vh 5vw 0vh 5vw',
	},
	drawer: {
		width: '80vw',
	},
	drawerContentName: {
		padding: '10vh 0vw 3vh 0vw',
		color: tools2go.colors.textPrimary,
		fontSize: '2.5vh',
		fontWeight: '700',
		letterSpacing: '2px',
	},
	menuButton: {
        margin: '0px',
        float: 'right',
        color: tools2go.colors.textPrimary,
    },
    linkSvg: {
        fill: '#0D344F',
        margin: '5vw',
        verticalAlign: 'middle',
		height: '5vw',
		width: '5vw',
    },
    drawerLinkGroup: {
        display: 'block',
    },
    drawerLink: {
        verticalAlign: 'middle',
        margin: '0vh 0vw',
        textTransform: 'capitalize',
        fontSize: '2.5vh',
    },
}));

const MainMenu = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const lastPage = useSelector(getLastPage);
	const [open, setOpen] = useState(false);
	const style = mainMenuStyles();
	const loggedInAccount = useSelector(getUsername);
	const dispatch = useDispatch();

	const handleOpen = event => {
		if (history.location.pathname === '/detail-view') {
			history.push(lastPage);
		} else {
			setOpen(true);
		}
	};

	const handleClose = event => {
		setOpen(false);
	};

	const userLogout = () => {
		history.push('/');
		dispatch(logout());
	}

	const DrawerContents = () => {

		return(
			<div className={style.drawer}>
				<div className={style.drawerHeader}>
					<IconButton className={style.menuButton} aria-label="menu" onClick={handleClose}>
						<MenuIcon />
					</IconButton>
					<Link className={style.drawerLinkGroup} to="/" onClick={handleClose}>
						<Avatar className={style.mainMenuAvatar} alt="Account" src={require('../../ressources/images/account_avatar.png')} />
						<h3 className={style.drawerContentName}>{loggedInAccount}</h3>
					</Link>
				</div>
				<Link className={style.drawerLinkGroup} to="/sites" onClick={handleClose}>
					<Hero className={style.linkSvg} />
					<Button className={style.drawerLink}>{t('mainMenu:sites')}</Button>
				</Link>
				<Link className={style.drawerLinkGroup} to="/my-tools" onClick={handleClose}>
					<Tools className={style.linkSvg} />
					<Button className={style.drawerLink}>{t('mainMenu:myTools')}</Button>
				</Link>
				<Link className={style.drawerLinkGroup} to="/search" onClick={handleClose}>
					<Search className={style.linkSvg} />
					<Button className={style.drawerLink}>{t('mainMenu:search')}</Button>
				</Link>
				<Link className={style.drawerLinkGroup} to="route" target="_blank" onClick={(event) => {event.preventDefault(); window.open('mailto:info@bitexpert.de');}}>
					<Info className={style.linkSvg} />
					<Button className={style.drawerLink} onClick={handleClose}>{t('mainMenu:support')}</Button>
				</Link>
				<Link className={style.drawerLinkGroup} to="/" onClick={userLogout}>
					<Logout className={style.linkSvg} />
					<Button className={style.drawerLink}>{t('account:logout')}</Button>
				</Link>
			</div>
		);
	};

	return (
		<div>
			<IconButton edge="start" color="inherit" aria-label="menu" onClick={handleOpen}>
				{history.location.pathname === '/detail-view' ? < ArrowBackIosIcon/> : <MenuIcon />}
			</IconButton>
			<Drawer open={open} onClose={handleClose}>
				<DrawerContents />
			</Drawer>
		</div>
	)
};

export default MainMenu;
