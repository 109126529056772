import { LOGOUT, LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_SESSION, LOGIN_SESSION_ERROR, CHANGE_USERNAME, CHANGE_PASSWORD, CHANGE_USERNAME_AND_PASSWORD } from '../actionTypes';

const initialState = {
	username: '',
	password: '',
	error: '',
	accessToken: '',
	customerId: '',
	userID: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS: {
			return {
				...state,
				username: action.payload.username,
				password: action.payload.password,
				accessToken: action.payload.accessToken,
				customerId: action.payload.customerId,
				error: '',
				userID: action.payload.userID,
			};
		}

		case LOGIN_ERROR: {
			return {
				...state,
				error: action.payload
			};
		}

		case LOGIN_SESSION: {
			return {
				...state
			};
		}

		case LOGIN_SESSION_ERROR: {
			return {
				...state,
				username: '',
				password: '',
				error: action.payload
			};
		}

		case LOGOUT: {
			return {
				...state,
				username: '',
				password: '',
				error: '',
				accessToken: '',
			};
		}

		case CHANGE_USERNAME: {
			return {
				...state,
				username: action.payload.username
			};
		}

		case CHANGE_PASSWORD: {
			return {
				...state,
				password: action.payload.password
			};
		}

		case CHANGE_USERNAME_AND_PASSWORD: {
			return {
				...state,
				username: action.payload.username,
				password: action.payload.password
			};
		}

		default:
			return state;
	}
}
