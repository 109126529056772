export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SESSION = 'LOGIN_SESSION';
export const LOGIN_SESSION_ERROR = 'LOGIN_SESSION_ERROR';
export const LOGOUT = 'LOGOUT';
export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_USERNAME_AND_PASSWORD = 'CHANGE_USERNAME_AND_PASSWORD';
export const GET_ARTICLES_FROM_DB = 'GET_ARTICLES_FROM_DB';
export const ERROR_ARTICLES_FROM_DB = 'ERROR_ARTICLES_FROM_DB';
export const GET_ARTICLEGROUPS_FROM_DB = 'GET_ARTICLEGROUPS_FROM_DB';
export const ERROR_ARTICLEGROUPS_FROM_DB = 'ERROR_ARTICLEGROUPS_FROM_DB';
export const GET_MACHINES_FROM_DB = 'GET_MACHINES_FROM_DB';
export const ERROR_MACHINES_FROM_DB = 'ERROR_MACHINES_FROM_DB';
export const GET_KEYS_FROM_DB = 'GET_KEYS_FROM_DB';
export const ERROR_KEYS_FROM_DB = 'ERROR_KEYS_FROM_DB';
export const GET_CUSTOMERS_FROM_DB = 'GET_CUSTOMERS_FROM_DB';
export const ERROR_CUSTOMERS_FROM_DB = 'ERROR_CUSTOMERS_FROM_DB';
export const GET_USER_FROM_DB = 'GET_USER_FROM_DB';
export const ERROR_USER_FROM_DB = 'ERROR_USER_FROM_DB';
export const GET_TRANSACTIONS_FROM_DB = 'GET_TRANSACTIONS_FROM_DB';
export const ERROR_TRANSACTIONS_FROM_DB = 'ERROR_TRANSACTIONS_FROM_DB';
export const GET_TRANSACTIONS_INFO_FROM_DB = 'GET_TRANSACTIONS_INFO_FROM_DB';
export const ERROR_TRANSACTIONS_INFO_FROM_DB = 'ERROR_TRANSACTIONS_INFO_FROM_DB';
export const GET_CONTAINERS_FROM_DB = 'GET_CONTAINERS_FROM_DB';
export const ERROR_CONTAINERS_FROM_DB = 'ERROR_CONTAINERS_FROM_DB';
export const GET_SEGMENTS_FROM_DB = 'GET_SEGMENTS_FROM_DB';
export const ERROR_SEGMENTS_FROM_DB = 'ERROR_SEGMENTS_FROM_DB';
export const GET_SPECIFIC_ARTICLES = 'GET_SPECIFIC_ARTICLES';
export const ERROR_SPECIFIC_ARTICLES = 'ERROR_SPECIFIC_ARTICLES';
export const GET_USER_ARTICLES = 'GET_USER_ARTICLES';
export const ERROR_USER_ARTICLES = 'ERROR_USER_ARTICLES';
export const GET_ACCOUNT_INFO = 'GET_ACCOUNT_INFO';
export const ERROR_ACCOUNT_INFO = 'ERROR_ACCOUNT_INFO';
export const GET_EXACT_ARTICLE = 'GET_EXACT_ARTICLE';
export const ERROR_EXACT_ARTICLE = 'ERROR_EXACT_ARTICLE';
export const SET_SELECTED_ARTICLE = 'SET_SELECTED_ARTICLE';
export const SET_LAST_PAGE = 'SET_LAST_PAGE';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const GET_SPECIFIC_SITE = 'GET_SPECIFIC_SITE';
export const SET_SPECIFIC_SITE = 'SET_SPECIFIC_SITE';
export const UPDATE_ARTICLES = 'UPDATE_ARTICLES';
export const ERROR_UPDATING_ARTICLES = 'ERROR_UPDATING_ARTICLES';
export const GET_LOCATIONS_FROM_DB = 'GET_LOCATIONS_FROM_DB';
export const ERROR_LOCATIONS_FROM_DB = 'ERROR_LOCATIONS_FROM_DB';
export const GET_TOOLS_IN_LOCATION_FROM_DB = 'GET_TOOLS_IN_LOCATION_FROM_DB';
export const ERROR_TOOLS_IN_LOCATION_FROM_DB = 'ERROR_TOOLS_IN_LOCATION_FROM_DB';
export const GET_TOOL_DETAIL_FROM_DB = 'GET_TOOL_DETAIL_FROM_DB';
export const ERROR_TOOL_DETAIL_FROM_DB = 'ERROR_TOOL_DETAIL_FROM_DB';
export const GET_MY_TOOLS_FROM_DB = 'GET_MY_TOOLS_FROM_DB';
export const ERROR_MY_TOOLS_FROM_DB = 'ERROR_MY_TOOLS_FROM_DB';
export const SEARCH_IN_DB = 'SEARCH_IN_DB';
export const ERROR_SEARCH_IN_DB = 'ERROR_SEARCH_IN_DB';
export const GET_ALL_AVAILABLE_TOOLS_FROM_DB = 'GET_ALL_AVAILABLE_TOOLS_FROM_DB';
export const ERROR_ALL_AVAILABLE_TOOLS_FROM_DB = 'ERROR_ALL_AVAILABLE_TOOLS_FROM_DB';
