import { combineReducers } from 'redux';
import account from './accountReducer.js';
import tools from './toolsReducer.js';
import general from './generalReducer.js';
import sites from './sitesReducer.js'

export default combineReducers({
    account,
    tools,
    general,
    sites
});
