import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from "./components/Login/Login";
import LandingPage from "./components/LandingPage/LandingPage"
import { getLoginError, getUsername } from './redux/selectors/accountSelectors.js';
import Header from './components/Header/Header.js';
import AvailableTools from './components/AvailableTools/AvailableTools.js';
import Search from './components/Search/Search.js';
import MyTools from './components/MyTools/MyTools.js';
import NotFound from './components/NotFound/NotFound.js';
import Account from './components/Account/Account.js';
import DetailView from './components/DetailView/DetailView.js';
import Sites from './components/Sites/Sites.js';
import SpecificSite from './components/SpecificSite/SpecificSite.js';
import './App.css';

const App = ({store}) => {
	const loggedInUser = useSelector(getUsername);
	const loginError = useSelector(getLoginError);
	const { t } = useTranslation();

	window.scrollTo(0,1);

	/* pull to refresh disabled
	// Check if user has session..
	useEffect(() => {
		// on mount
		PullToRefresh.init({
			mainElement: 'body',
			onRefresh() {
				const currentState = store.getState();
				if (
					!!currentState.account === false ||
					!!currentState.account.accessToken === false
				) {
					console.log('reload page', Date.now())
					return window.location.reload();
				}

				const allArticles = currentState.tools.articles;

				dispatch(updateArticles(currentState.account.accessToken, allArticles[allArticles.length - 1].id));

				console.log('refresh data', Date.now())
			},
			// iconArrow: ReactDOMServer.renderToString(
			//	 <FontAwesomeIcon icon={faSyncAlt} />
			// ),
			// iconRefreshing: ReactDOMServer.renderToString(
			//	 <FontAwesomeIcon icon={faSyncAlt} spin={true} />
			// ),
		});
		loginSession();
		return () => {
			// on unmount
			PullToRefresh.destroyAll();
		}
	// eslint-disable-next-line
	}, []); */

	if (!loggedInUser || loginError) {
		return (
			<div>
				<div id='portraitMode'>
					<Router>
						<Login />
					</Router>
				</div>
				<div id='landscapeMode'>
					<p>{t('general:landscapeMode')}</p>
				</div>
			</div>
		)
	}

	return (
		<div>
			<Router>
				<div id='portraitMode'>
					<Header />
					<Switch>
						<Route exact path="/" component={LandingPage} />
						<Route path="/available-tools" component={AvailableTools} />
						<Route path="/search" component={Search} />
						<Route path="/my-tools" component={MyTools} />
						<Route path="/my-account" component={Account} />
						<Route path="/detail-view" component={DetailView} />
						<Route path="/sites" component={Sites} />
						<Route path="/specific-site" component={SpecificSite} />
						<Route path='/support' component={() => {
							return null;
						}}/>
						<Route component={NotFound} />
					</Switch>
				</div>
				<div id='landscapeMode'>
					<p>{t('general:landscapeMode')}</p>
				</div>
			</Router>
		</div>
	);
};

export default App;
