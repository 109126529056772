import {
    SET_SELECTED_ARTICLE,
    GET_ARTICLES_FROM_DB,
    ERROR_ARTICLES_FROM_DB,
    GET_ARTICLEGROUPS_FROM_DB,
    ERROR_ARTICLEGROUPS_FROM_DB,
    GET_MACHINES_FROM_DB,
    ERROR_MACHINES_FROM_DB,
    GET_KEYS_FROM_DB,
    ERROR_KEYS_FROM_DB,
    GET_CUSTOMERS_FROM_DB,
    ERROR_CUSTOMERS_FROM_DB,
    GET_USER_FROM_DB,
    ERROR_USER_FROM_DB,
    GET_TRANSACTIONS_FROM_DB,
    ERROR_TRANSACTIONS_FROM_DB,
    GET_TRANSACTIONS_INFO_FROM_DB,
    ERROR_TRANSACTIONS_INFO_FROM_DB,
    GET_CONTAINERS_FROM_DB,
    ERROR_CONTAINERS_FROM_DB,
    GET_SEGMENTS_FROM_DB,
    ERROR_SEGMENTS_FROM_DB,
    UPDATE_ARTICLES,
    ERROR_UPDATING_ARTICLES,
    GET_LOCATIONS_FROM_DB,
    ERROR_LOCATIONS_FROM_DB,
    GET_TOOLS_IN_LOCATION_FROM_DB,
    ERROR_TOOLS_IN_LOCATION_FROM_DB,
    GET_TOOL_DETAIL_FROM_DB,
    ERROR_TOOL_DETAIL_FROM_DB,
    GET_MY_TOOLS_FROM_DB,
    ERROR_MY_TOOLS_FROM_DB,
    GET_ALL_AVAILABLE_TOOLS_FROM_DB,
    ERROR_ALL_AVAILABLE_TOOLS_FROM_DB,
} from '../actionTypes';
import API from '../../api/api'

const api = new API();

export const setSelectedArticleInStore = (toolId) => {
    return({
        type: SET_SELECTED_ARTICLE,
        payload: toolId
    });
}

export const setArticlesError = (toolId) => {
    return({
        type: ERROR_ARTICLES_FROM_DB,
        payload: ''
    });
}

export const getLocationsFromDB = (accessToken) => {
    return dispatch => api.getLocations(accessToken)
        .then(res => {
            dispatch({
                type: GET_LOCATIONS_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_LOCATIONS_FROM_DB,
                payload: err
            })
        })
}

export const getToolsInLocationFromDB = (accessToken, containerId) => {
    return dispatch => api.getToolsInLocation(accessToken, containerId)
        .then(res => {
            dispatch({
                type: GET_TOOLS_IN_LOCATION_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_TOOLS_IN_LOCATION_FROM_DB,
                payload: err
            })
        })
}

export const getToolDetailFromDB = (accessToken, toolId) => {
    return dispatch => api.getToolDetails(accessToken, toolId)
        .then(res => {
            dispatch({
                type: GET_TOOL_DETAIL_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_TOOL_DETAIL_FROM_DB,
                payload: err
            })
        })
}

export const getMyToolsFromDB = (accessToken, customerId) => {
    return dispatch => api.getMyTools(accessToken, customerId)
        .then(res => {
            dispatch({
                type: GET_MY_TOOLS_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_MY_TOOLS_FROM_DB,
                payload: err
            })
        })
}

export const getAllAvailableToolsFromDB = (accessToken) => {
    return dispatch => api.getAllAvailableTools(accessToken)
        .then(res => {
            dispatch({
                type: GET_ALL_AVAILABLE_TOOLS_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_ALL_AVAILABLE_TOOLS_FROM_DB,
                payload: err
            })
        })
}

export const getAllArticlesFromDb = (accessToken) => {
    return dispatch => api.getArticles(accessToken)
        .then(res => {
            dispatch({
                type: GET_ARTICLES_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_ARTICLES_FROM_DB,
                payload: err
            })
        })
}

export const getAllArticleGroupsFromDb = (accessToken) => {
    return dispatch => api.getArticleGroups(accessToken)
        .then(res => {
            dispatch({
                type: GET_ARTICLEGROUPS_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_ARTICLEGROUPS_FROM_DB,
                payload: err
            })
        })
}

export const getAllMachinesFromDb = (accessToken) => {
    return dispatch => api.getMachines(accessToken)
        .then(res => {
            dispatch({
                type: GET_MACHINES_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_MACHINES_FROM_DB,
                payload: err
            })
        })
}

export const getAllKeysFromDb = (accessToken) => {
    return dispatch => api.getKeys(accessToken)
        .then(res => {
            dispatch({
                type: GET_KEYS_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_KEYS_FROM_DB,
                payload: err
            })
        })
}

export const getAllCustomersFromDb = (accessToken) => {
    return dispatch => api.getCustomers(accessToken)
        .then(res => {
            dispatch({
                type: GET_CUSTOMERS_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_CUSTOMERS_FROM_DB,
                payload: err
            })
        })
}

export const getAllUserFromDb = (accessToken) => {
    return dispatch => api.getUsers(accessToken)
        .then(res => {
            dispatch({
                type: GET_USER_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_USER_FROM_DB,
                payload: err
            })
        })
}

export const getAllTransactionsFromDb = (accessToken) => {
    return dispatch => api.getTransactions(accessToken)
        .then(res => {
            dispatch({
                type: GET_TRANSACTIONS_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_TRANSACTIONS_FROM_DB,
                payload: err
            })
        })
}
export const getAllTransactionsInfoFromDb = (accessToken) => {
    return dispatch => api.getTransactionsInfo(accessToken)
        .then(res => {
            dispatch({
                type: GET_TRANSACTIONS_INFO_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_TRANSACTIONS_INFO_FROM_DB,
                payload: err
            })
        })
}

export const getAllContainersFromDb = (accessToken) => {
    return dispatch => api.getContainers(accessToken)
        .then(res => {
            dispatch({
                type: GET_CONTAINERS_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_CONTAINERS_FROM_DB,
                payload: err
            })
        })
}

export const getAllSegmentsFromDb = (accessToken) => {
    return dispatch => api.getSegments(accessToken)
        .then(res => {
            dispatch({
                type: GET_SEGMENTS_FROM_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_SEGMENTS_FROM_DB,
                payload: err
            })
        })
}

export const updateArticles = (accessToken, start) => {
    return dispatch => api.getArticles(accessToken, start)
        .then(res => {
            dispatch({
                type: UPDATE_ARTICLES,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_UPDATING_ARTICLES,
                payload: err
            })
        })
}
