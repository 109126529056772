import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { composeWithDevTools } from 'redux-devtools-extension';

import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './redux/reducers';
import { tools2go } from './components/Theme/Theme.js';
import './i18n/i18n.js';

//composeWithDevTools as a store enhancer is necessary for dev and will be removed for prod
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
    );

render(
    <Provider store={store}>
        <StylesProvider>
            <ThemeProvider theme={tools2go}>
                    <App store={store} />
            </ThemeProvider>
        </StylesProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
