import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import MainMenu from '../MainMenu/MainMenu.js';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { getSpecificSiteFromStore, getLocationsFromStore } from '../../redux/selectors/sitesSelectors.js';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const headerStyles = makeStyles(tools2go => ({
	appBar: {
		width: '96vw',
		height: '10vh',
	},
	toolBar: {
		width: '100vw',
		height: '10vh',
	},
	appBarMenuButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginLeft: '8vw',
		borderRadius: '0px',
		width: '20vw',
		height: '10vh',
	},
	pageTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		textAlign: 'center',
		width: 'auto',
		height: '10vh',
	},
	accountInfoButton: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '4vw',
		borderRadius: '0px',
		width: '20vw',
		height: '10vh',
	},
	accountIcon: {
		width: '100%'
	},
}));

const Header = props => {
	const specificSite = useSelector(getSpecificSiteFromStore);
	const locations = useSelector(getLocationsFromStore);
	const history = useHistory();
	const { t } = useTranslation();
	const style = headerStyles();
	let pageTitle, locationName = '';

	if (!!locations) {
		locations.map((location, key) => {
			if (location.id.toString() === specificSite) {
				locationName = location.name;
			}
			return null;
		});
	}

	if (locationName === '') {
		locationName = specificSite;
	}

	switch (history.location.pathname) {
		case '/':
			pageTitle = t('header:homepage');
			break;
		case '/available-tools':
			pageTitle = t('header:availableTools');
			break;
		case '/search':
			pageTitle = t('header:search');
			break;
		case '/my-tools':
			pageTitle = t('header:myTools');
			break;
		case '/my-account':
			pageTitle = t('header:myAccount');
			break;
		case '/detail-view':
			pageTitle = t('header:homepage');
			break;
		case '/sites':
			pageTitle = t('header:sites');
			break;
		case '/specific-site':
			pageTitle = t('header:specificSite') + ' ' + locationName;
			break;
		default:
			pageTitle = t('header:homepage');
	}

	return (
		<AppBar className={style.appBar} position="static">
			<Toolbar disableGutters={true} className={style.toolBar}>
				<div className={style.appBarMenuButton}>
					<MainMenu />
				</div>
				<Typography variant="h6" className={style.pageTitle}>
					{pageTitle}
				</Typography>
				<Link to='/my-account' className={style.accountInfoButton}>
					<IconButton>
						<img className={style.accountIcon} src={require('../../ressources/svgs/2G_Icon.svg')} alt='Account'/>
					</IconButton>
				</Link>
			</Toolbar>
		</AppBar>
	)
};

export default Header;
