import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/accountActions';
import { getLoginError } from "../../redux/selectors/accountSelectors";
import { Button, TextField, Modal } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Footer from '../Footer/Footer.js';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const loginStyles = makeStyles(tools2go => ({
	loginView: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        height: '100vh',
    },
    modal: {
        display: 'block',
        backgroundColor: tools2go.colors.bgSecondary,
        width: '80vw',
        height: '80vh',
        margin: '10vh 10vw',
        textAlign: 'center',
    },
	modalButton: {
    	background: tools2go.colors.bgPrimary,
    	border: 0,
    	borderRadius: 5,
    	color: tools2go.colors.textPrimary,
    	height: 50,
		width: '60vw',
		margin: '0',
		marginTop: '2vh',
		marginBottom: '25vh',
		textTransform: 'none',
		fontWeight: 700,
	},
	loginHeading: {
        textAlign: 'center',
        fontWeight: 'lighter',
        fontSize: '36px',
        color: tools2go.colors.textPrimary,
        paddingTop: '23vh',
		margin: '0px',
    },
	loginSubheading: {
        textAlign: 'center',
        fontWeight: 'lighter',
        fontSize: '18px',
        color: tools2go.colors.textPrimary,
        padding: '1vh 0vw',
    },
    loginButton: {
        width: '80vw',
		fontWeight: '700',
		"&:hover": {
			backgroundColor: '#0E344F',
			color: '#FFFFFF',
		},
    },
	loginErrorTitle: {
		width: '100%',
		paddingTop: '20vh',
	},
	loginErrorDesc: {
		paddingTop: '20vh',
		width: '100%',
	},
}));

const Login = () => {
	const dispatch = useDispatch();
	const hasError = useSelector(getLoginError);
	const style = loginStyles();
	const { t } = useTranslation();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [open, setOpen] = useState(false);
	const history = useHistory();

	const handleOpen = () => {
	    setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleUsernameChange = e => {
		setUsername(e.target.value);
	};

	const handlePasswordChange = e => {
		setPassword(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		dispatch(login(username, password));
		history.push('/');
		if (hasError.length > 1) {
        	handleOpen();
        }
	};

	return (
		<div className={style.loginView}>
			<h1 className={style.loginHeading}>{t('login:login')}</h1>
			<p className={style.loginSubheading}>Tool Hero 2.0</p>
    		<form onSubmit={handleSubmit} error={hasError && hasError.toString()}>
				{/*The below Element requires a "required" tag, but is excluded from DEV work>*/}
    			<TextField
					className={style.textField}
					name='username'
					label=''
					placeholder={t('login:username')}
					autoComplete="current-username"
					value={username}
					onChange={handleUsernameChange}
					margin='normal'
					variant='outlined'
				/>
				{/*The below Element requires a "required" tag, but is excluded from DEV work>*/}
    			<TextField
					className={style.textField}
					name='password'
					type='password'
					label=''
					placeholder={t('login:password')}
					autoComplete="current-password"
					value={password}
					onChange={handlePasswordChange}
					margin='normal'
					variant='outlined'
				/>
    			<Button className={style.loginButton} variant="contained" color="secondary" type='submit'>{t('login:login')}</Button>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={open}
					onClose={handleClose}
				>
					<div className={style.modal} >
						<h2 className={style.loginErrorTitle}>{t('login:cannotLogin')}</h2>
						<p className={style.loginErrorDesc}>
							{hasError.TypeError}
						</p>
						<Button className={style.modalButton} id='modalButton' variant="contained" color="secondary" type='submit' onClick={handleClose}>{t('login:ok')}</Button>
					</div>
				</Modal>
			</form>
			<Footer />
		</div>
	)
};

export default Login;
