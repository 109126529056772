import ky from 'ky';

const API_ENDPOINT = process.env.REACT_APP_API_HOST ? `${process.env.REACT_APP_API_HOST}`:'/api';
const retryOptions = {
        limit: 10,
        methods: ['post', 'get'],
        statusCodes: [401, 500, 502]
};

class API {

    async login(username, password) {
        const response = await ky.get(`${API_ENDPOINT}/login`, {
            headers: {
                'username': username,
                'password': password
            },
            retry: retryOptions,
            throwHttpErrors: false
        }).json();

        if (response.error) {
            throw response.error;
        }

        return response;
    }

    async getLocations(sessionID) {
        const response = await ky.get(`${API_ENDPOINT}/v2/locations`, {
            headers: {
                'x-access-token': sessionID
            },
            retry: retryOptions,
            throwHttpErrors: false
        }).json();

        if (response.error) {
            throw response.error;
        }

        return response;
    }

    async getToolsInLocation(sessionID, containerID) {
        const response = await ky.get(`${API_ENDPOINT}/v2/available-tools?container_id=${containerID}`, {
            headers: {
                'x-access-token': sessionID
            },
            retry: retryOptions,
            throwHttpErrors: false
        }).json();

        if (response.error) {
            throw response.error;
        }

        return response;
    }

    async getToolDetails(sessionID, articleID) {
        const response = await ky.get(`${API_ENDPOINT}/v2/tool-detail?article_id=${articleID}`, {
            headers: {
                'x-access-token': sessionID
            },
            retry: retryOptions,
            throwHttpErrors: false
        }).json();

        if (response.error) {
            throw response.error;
        }

        return response;
    }

    async getMyTools(sessionID, customerID) {
        const response = await ky.get(`${API_ENDPOINT}/v2/my-tools?key_id=${customerID}`, {
            headers: {
                'x-access-token': sessionID
            },
            retry: retryOptions,
            throwHttpErrors: false
        }).json();

        if (response.error) {
            throw response.error;
        }

        return response;
    }

    async getAllAvailableTools(sessionID) {
        const response = await ky.get(`${API_ENDPOINT}/v2/available-tools`, {
            headers: {
                'x-access-token': sessionID
            },
            retry: retryOptions,
            throwHttpErrors: false
        }).json();

        if (response.error) {
            throw response.error;
        }

        return response;
    }

    async searchInDB(sessionID, searchTerm) {
        const response = await ky.get(`${API_ENDPOINT}/v2/search-tools?search_term=${searchTerm}`, {
            headers: {
                'x-access-token': sessionID
            },
            retry: retryOptions,
            throwHttpErrors: false
        }).json();

        if (response.error) {
            throw response.error;
        }

        return response;
    }

    // @todo: migrate to new database endpoints
    async searchDataSet(accessToken, eTable, searchKey = '', xmlData = '') {
        const response = await ky.post(`${API_ENDPOINT}/searchDataSet`, {
            json: {
                accessToken: accessToken,
                eTable: eTable,
                searchKey: searchKey,
                xmlData: xmlData
            },
            retry: retryOptions
        }).json();

        console.log(response);
    }

    // @todo: migrate to new database endpoints
    async searchDataRecord(accessToken, eTable, searchKey = '', xmlData = '') {
        const response = await ky.post(`${API_ENDPOINT}/searchDataRecord`, {
            json: {
                accessToken: accessToken,
                eTable: eTable,
                searchKey: searchKey,
                xmlData: xmlData
            },
            retry: retryOptions
        }).json();

        console.log(response);
    }

    async getUserIdByUsername(accessToken, username) {
        const response = await ky.get(`${API_ENDPOINT}/user`, {
            headers: {
                'x-access-token': accessToken,
                'x-username': username
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getUsers(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/users`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        });
        return response.json();
    }

    async getArticles(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/articles`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getMachines(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/machines`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getMaterials(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/materials`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getContainers(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/containers`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getStations(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/stations`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getStationById(accessToken, stationId) {
        const response = await ky.post(`${API_ENDPOINT}/station/${stationId}`, {
            headers: {
                'x-access-token': accessToken
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getTransactions(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/transactions`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getTransactionsInfo(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/transactions_info`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getKeys(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/keys`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getCustomers(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/customers`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getArticleGroups(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/article_groups`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

    async getSegments(accessToken, start) {
        const response = await ky.get(`${API_ENDPOINT}/segments`, {
            headers: {
                'x-access-token': accessToken,
                'x-start': start
            },
            retry: retryOptions
        }).json();
        return response;
    }

}

export default API;
