export const getArticles = store => store.tools.articles;
export const getMyArticles = store => {
    let rentedMachines = [];

    if (!store.tools.machines || !store.tools.articles) {
        return [];
    }

    store.tools.machines.forEach( machine => {

        if (machine.customer_id === store.account.customerId) {
            rentedMachines.push(machine.article_id)
            }
        }
    );

    return store.tools.articles.filter(article => rentedMachines.includes(article.id));
};
export const getArticlesError = store => store.tools.errorArticles;
export const getSelectedArticleFromStore = store => store.tools.selectedTool;
export const getMachinesFromStore = store => store.tools.machines;
export const getCustomersFromStore = store => store.tools.customers;
export const getTransactionsFromStore = store => store.tools.transactions;
export const getTransactionInfoFromStore = store => store.tools.transactionsInfo;
export const getContainersFromStore = store => store.tools.containers;
export const getKeysFromStore = store => store.tools.keys;
export const getLocationsFromStore = store => store.tools.locations;
export const getToolsInLocationErrorFromStore = store => store.tools.errorToolsInLocation;
export const getToolsInLocationFromStore = store => store.tools.toolsInLocation;
export const getToolDetailsFromStore = store => store.tools.toolDetail;
export const getToolDetailsError = store => store.tools.errorToolDetail;
export const getToolsForUser = store => store.tools.myTools;
export const getToolsForUserError = store => store.tools.errorMyTools;
export const getAllAvailableTools = store => store.tools.allAvailableTools;
export const getAllAvailableToolsError = store => store.tools.errorAllAvailableTools;
