import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { logout } from '../../redux/actions/accountActions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';

const accountStyles = makeStyles(tools2go => ({
	view: {
        minHeight: '90vh',
        width: '100vw',
        textAlign: 'center',
        marginTop: '-10vh',
        paddingTop: '10vh',
    },
    loginButton: {
        width: '80vw',
    },
}));

const Account = () => {
	const dispatch = useDispatch();
	const style = accountStyles();
	const { t } = useTranslation();
	const history = useHistory();

	const userLogout = () => {
		history.push('/');
		dispatch(logout());
	}

	return (
		<div className={style.view}>
				<Button variant="contained" color="secondary" className={style.loginButton} onClick={userLogout}>{t('account:logout')}</Button>
		</div>
	)
};

export default Account;
