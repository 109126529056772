import { SET_LAST_PAGE, SEARCH_IN_DB, ERROR_SEARCH_IN_DB } from '../actionTypes';

const initialState = {
	lastPage: '/',
	searchResult: [],
	searchError: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_LAST_PAGE: {
			return {
				...state,
				lastPage: action.payload
			};
		}

		case SEARCH_IN_DB: {
			return {
				...state,
				searchResult: action.payload
			};
		}

		case ERROR_SEARCH_IN_DB: {
			return {
				...state,
				searchError: action.payload
			};
		}

		default:
			return state;
	}
}
