import {
    GET_ARTICLES_FROM_DB,
    GET_ARTICLEGROUPS_FROM_DB,
    GET_MACHINES_FROM_DB,
    GET_KEYS_FROM_DB,
    GET_CUSTOMERS_FROM_DB,
    GET_USER_FROM_DB,
    GET_TRANSACTIONS_FROM_DB,
    GET_TRANSACTIONS_INFO_FROM_DB,
    GET_CONTAINERS_FROM_DB,
    GET_SEGMENTS_FROM_DB,
    GET_EXACT_ARTICLE,
    GET_USER_ARTICLES,
    GET_SPECIFIC_ARTICLES,
    ERROR_ARTICLES_FROM_DB,
    ERROR_ARTICLEGROUPS_FROM_DB,
    ERROR_MACHINES_FROM_DB,
    ERROR_KEYS_FROM_DB,
    ERROR_CUSTOMERS_FROM_DB,
    ERROR_USER_FROM_DB,
    ERROR_TRANSACTIONS_FROM_DB,
    ERROR_TRANSACTIONS_INFO_FROM_DB,
    ERROR_CONTAINERS_FROM_DB,
    ERROR_SEGMENTS_FROM_DB,
    ERROR_EXACT_ARTICLE,
    ERROR_USER_ARTICLES,
    ERROR_SPECIFIC_ARTICLES,
    SET_SELECTED_ARTICLE,
    ERROR_UPDATING_ARTICLES,
    UPDATE_ARTICLES,
    GET_LOCATIONS_FROM_DB,
    ERROR_LOCATIONS_FROM_DB,
    GET_TOOLS_IN_LOCATION_FROM_DB,
    ERROR_TOOLS_IN_LOCATION_FROM_DB,
    GET_TOOL_DETAIL_FROM_DB,
    ERROR_TOOL_DETAIL_FROM_DB,
    GET_MY_TOOLS_FROM_DB,
    ERROR_MY_TOOLS_FROM_DB,
    GET_ALL_AVAILABLE_TOOLS_FROM_DB,
    ERROR_ALL_AVAILABLE_TOOLS_FROM_DB,
} from '../actionTypes';

const initialState = {
    selectedTool: '',
    articles: '',
    articleGroups: '',
    machines: '',
    keys: '',
    customers: '',
    user: '',
    transactions: '',
    transactionsInfo: '',
    containers: '',
    segments: '',
    locations: '',
    toolsInLocation: '',
    toolDetail: '',
    errorArticles: '',
    errorArticleGroups: '',
    errorMachines: '',
    errorKeys: '',
    errorCustomers: '',
    errorUser: '',
    errorTransactions: '',
    errorTransactionsInfo: '',
    errorContainers: '',
    errorSegments: '',
    errorLocations: '',
    errorToolsInLocation: '',
    errorToolDetail: '',
    myTools: '',
    errorMyTools: '',
    allAvailableTools: '',
    errorAllAvailableTools: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_LOCATIONS_FROM_DB: {
            return {
                ...state,
                locations: action.payload
			};
        }

        case ERROR_LOCATIONS_FROM_DB: {
			return {
				...state,
				errorLocations: action.payload
			};
		}

        case GET_TOOLS_IN_LOCATION_FROM_DB: {
            return {
                ...state,
                toolsInLocation: action.payload
			};
        }

        case ERROR_TOOLS_IN_LOCATION_FROM_DB: {
			return {
				...state,
				errorToolsInLocation: action.payload
			};
		}

        case GET_TOOL_DETAIL_FROM_DB: {
            return {
                ...state,
                toolDetail: action.payload
            };
        }

        case ERROR_TOOL_DETAIL_FROM_DB: {
            return {
                ...state,
                errorToolDetail: action.payload
            };
        }

        case GET_MY_TOOLS_FROM_DB: {
            return {
                ...state,
                myTools: action.payload
            };
        }

        case ERROR_MY_TOOLS_FROM_DB: {
            return {
                ...state,
                errorMyTools: action.payload
            };
        }

        case GET_ALL_AVAILABLE_TOOLS_FROM_DB: {
            return {
                ...state,
                allAvailableTools: action.payload,
                errorAllAvailableTools: ''
			};
        }

        case ERROR_ALL_AVAILABLE_TOOLS_FROM_DB: {
			return {
				...state,
				errorAllAvailableTools: action.payload
			};
		}

        case GET_ARTICLES_FROM_DB: {
            return {
                ...state,
                articles: action.payload
			};
        }

        case ERROR_ARTICLES_FROM_DB: {
			return {
				...state,
				errorArticles: action.payload
			};
		}

        case GET_ARTICLEGROUPS_FROM_DB: {
            return {
                ...state,
                articleGroups: action.payload
			};
        }

        case ERROR_ARTICLEGROUPS_FROM_DB: {
			return {
				...state,
				errorArticleGroups: action.payload
			};
		}

        case GET_MACHINES_FROM_DB: {
            return {
                ...state,
                machines: action.payload
			};
        }

        case ERROR_MACHINES_FROM_DB: {
			return {
				...state,
				errorMachines: action.payload
			};
		}

        case GET_KEYS_FROM_DB: {
            return {
                ...state,
                keys: action.payload
			};
        }

        case ERROR_KEYS_FROM_DB: {
			return {
				...state,
				errorKeys: action.payload
			};
		}

        case GET_CUSTOMERS_FROM_DB: {
            return {
                ...state,
                customers: action.payload
			};
        }

        case ERROR_CUSTOMERS_FROM_DB: {
			return {
				...state,
				errorCustomers: action.payload
			};
		}

        case GET_USER_FROM_DB: {
            return {
                ...state,
                user: action.payload.fleetweb.users.user
			};
        }

        case ERROR_USER_FROM_DB: {
			return {
				...state,
				errorUser: action.payload
			};
		}

        case GET_TRANSACTIONS_FROM_DB: {
            return {
                ...state,
                transactions: action.payload
			};
        }

        case ERROR_TRANSACTIONS_FROM_DB: {
			return {
				...state,
				errorTransactions: action.payload
			};
		}

        case GET_TRANSACTIONS_INFO_FROM_DB: {
            return {
                ...state,
                transactionsInfo: action.payload
			};
        }

        case ERROR_TRANSACTIONS_INFO_FROM_DB: {
			return {
				...state,
				errorTransactionsInfo: action.payload
			};
        }

        case GET_CONTAINERS_FROM_DB: {
            return {
                ...state,
                containers: action.payload
			};
        }

        case ERROR_CONTAINERS_FROM_DB: {
			return {
				...state,
				errorContainers: action.payload
			};
		}

        case GET_SEGMENTS_FROM_DB: {
            return {
                ...state,
                segments: action.payload
			};
        }

        case ERROR_SEGMENTS_FROM_DB: {
			return {
				...state,
				errorSegments: action.payload
			};
		}

        case GET_EXACT_ARTICLE: {
            return {
				...state,
			};
        }

        case ERROR_EXACT_ARTICLE: {
			return {
				...state,
				error: action.payload
			};
		}

        case GET_USER_ARTICLES: {
            return {
				...state,
			};
        }

        case ERROR_USER_ARTICLES: {
			return {
				...state,
				error: action.payload
			};
		}

        case GET_SPECIFIC_ARTICLES: {
            return {
				...state,
			};
        }

        case ERROR_SPECIFIC_ARTICLES: {
			return {
				...state,
				error: action.payload
			};
		}

        case SET_SELECTED_ARTICLE: {
			return {
				...state,
				selectedTool: action.payload
			};
		}

        case UPDATE_ARTICLES: {

            return {
                ...state,
                articles: [...state.articles, ...action.payload]
            }
        }

        case ERROR_UPDATING_ARTICLES: {
            return {
                ...state,
                error: action.payload,
            }
        }

        default:
            return state;
    }
}
