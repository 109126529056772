import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLocationsFromStore } from '../../redux/selectors/toolsSelectors.js';
import { getAccessTokenFromStore } from '../../redux/selectors/accountSelectors.js';
import { setSpecificSiteInStore } from '../../redux/actions/sitesActions.js';
import { getAllAvailableToolsFromDB, getToolsInLocationFromDB } from '../../redux/actions/toolsActions.js';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';

const sitesStyles = makeStyles(tools2go => ({
	view: {
        minHeight: '90vh',
        width: '100vw',
        textAlign: 'center',
        marginTop: '-10vh',
        paddingTop: '10vh',
    },
	siteImageHeader: {
		height: '25vh',
		objectFit: 'cover',
        width: '100vw',
        float: 'left',
        backgroundColor: '#FFFFFF',
    },
    sitesViewSites: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        float: 'left',
        width: '100vw',
        minHeight: '57vh',
        paddingTop: '3vh',
        backgroundImage: 'linear-gradient(0deg, #ECECEC, #FFFFFF)',
        color: tools2go.colors.textSecondary,
    },
    sitesPageDiv: {
        display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		flexDirection: 'column',
        paddingLeft: '5vw',
        backgroundColor: tools2go.colors.bgSecondary,
        width: '85vw',
        minHeight: '10vh',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: tools2go.colors.grey,
    },
    sitesHeader: {
		width: '100%',
		fontSize: '16px',
		textAlign: 'left',
        marginBlockStart: '0px',
        marginInlineStart: '0px',
        marginBlockEnd: '0px',
        marginInlineEnd: '0px',
        fontWeight: '700',
    },
	sitesAddress: {
		width: '100%',
        fontSize: '12px',
		fontWeight: '300',
		color: '#878787',
		textAlign: 'left',
    },
}));

const Sites = () => {
	const locations = useSelector(getLocationsFromStore);
	const accessToken = useSelector(getAccessTokenFromStore);
	const history = useHistory();
	const dispatch = useDispatch();
	const style = sitesStyles();
	const { t } = useTranslation();

	const handleClick = e => {
		if (e.currentTarget.getAttribute('site') === 'everything') {
			dispatch(getAllAvailableToolsFromDB(accessToken));
			history.push('/available-tools');
		} else {
			dispatch(setSpecificSiteInStore(e.currentTarget.getAttribute('site')));
			dispatch(getToolsInLocationFromDB(accessToken, e.currentTarget.getAttribute('site')));
			history.push('/specific-site');
		}
	}

	return (
		<div className={style.view}>
			<img className={style.siteImageHeader} src={require('../../ressources/images/header_Staebler.png')} alt='Company Logo' />
			<div className={style.sitesViewSites}>
				<div className={style.sitesPageDiv} site='everything' onClick={handleClick}>
					<h3 className={style.sitesHeader}>{t('sites:everything')}</h3>
				</div>
				{Array.isArray(locations) && locations.map((location, key) =>
					<div key={key} className={style.sitesPageDiv} site={location.id} onClick={handleClick}>
						<h3 className={style.sitesHeader}>{t('sites:site')} {location.name}</h3>
						<h3 className={style.sitesAddress}>{location.location}</h3>
					</div>
				)}
			</div>
		</div>
	);
};

export default Sites;
