import React from 'react';
import { tableStyle } from '../Theme/Theme.js';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import { getToolsForUser, getToolsForUserError } from '../../redux/selectors/toolsSelectors.js';
import { setSelectedArticleInStore, getAllArticlesFromDb, setArticlesError, getToolDetailFromDB } from '../../redux/actions/toolsActions.js';
import { getAccessTokenFromStore } from '../../redux/selectors/accountSelectors.js';
import { useHistory } from 'react-router-dom';
import { setLastPage } from '../../redux/actions/generalActions.js';
import { useTranslation } from "react-i18next";
import Image from "react-graceful-image";
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Hero } from '../../ressources/svgs/hero.svg';

const myToolsStyles = makeStyles(tools2go => ({
	view: {
        minHeight: '90vh',
        width: '100vw',
        textAlign: 'center',
        marginTop: '-10vh',
        paddingTop: '10vh',
    },
	dataNotLoadedSvg: {
		width: '80vw',
		margin: '5vh auto 5vh auto',
		fill: '#FFFFFF',
	},
	dataNotLoadedHeader: {
		width: '80vw',
		margin: '5vh auto 5vh auto',
		color: '#FFFFFF',
		fontWeight: '300',
	},
	dataNotLoadedButton: {
		width: '80vw',
		margin: 'auto',
		fontWeight: '700',
	},
	SearchAvatar: {
        textAlign: 'center',
        maxWidth: '10vh',
        width: 'auto',
        maxHeight: '7vh',
        height: 'auto',
        border: '0px',
        borderColor: '#FFFFFF',
		borderRadius: '0',
    },
	missingImage: {
		textAlign: 'center',
        maxWidth: '10vh',
        width: 'auto',
        maxHeight: '7vh',
        height: 'auto',
        border: '0px',
        borderColor: '#FFFFFF',
		borderRadius: '0',
		backgroundColor: '#E4E4E4',
		fill: '#747474',
	},
    searchTitle: {
        color: '#0D344F',
        fontSize: '1rem',
    },
    searchDesc: {
        color: '#878787',
    },
    inStock: {
        background: 'linear-gradient(127deg, #1F6487 0%, #0D344F 82%)',
    },
    outOfStock: {
        background: 'linear-gradient(127deg, #FF0038 0%, #78001C 82%)',
    },
}));

const MyTools = () => {
	const { t } = useTranslation();
	const accessToken = useSelector(getAccessTokenFromStore);
	const dispatch = useDispatch();
	const history = useHistory();
	const style = myToolsStyles();
	const toolsForUser = useSelector(getToolsForUser);
	const errorToolsForUser = useSelector(getToolsForUserError);
	let showFilter = false

	const loadAgain = () => {
		dispatch(setArticlesError());
		dispatch(getAllArticlesFromDb(accessToken));
	}

    if (toolsForUser === '') {
		return(
			<div className={style.view}>
				<Hero className={style.dataNotLoadedSvg} />
    			{errorToolsForUser==='' ? (<h3 className={style.dataNotLoadedHeader}>{t('search:loading')}</h3>) : (<h3 className={style.dataNotLoadedHeader}>{t('search:cannotGetData')}: {errorToolsForUser.name}</h3>)}
				{errorToolsForUser==='' ? (<h3 className={style.dataNotLoadedHeader}>{t('search:pleaseWait')}</h3>) : (<Button className={style.dataNotLoadedButton} color='secondary' variant="contained" onClick={loadAgain}>{t('search:tryAgain')}</Button>)}
    		</div>
        );
    } else {
		if (toolsForUser.length > 1) {
			showFilter = true;
		} else {
			showFilter = false;
		}
	}

	const defaultImage = (ev) => {
		ev.target.src = require('../../ressources/svgs/TH_Logo.svg');
		ev.target.className = style.missingImage;
	};

	// @todo: check if it is more reliable to use the base64 string or the extra requests (# of requests, load and caching)
	// the previous implementation used the following code to output images
	// {'https://gradwohl-test.de/gts/base/ArticlePicture.asp?article_id=' + rowData.id}

	return (
		<div className={style.view}>
			<MaterialTable
				title=''
				options={{search: showFilter, paging: false, searchFieldStyle:tableStyle.searchFieldStyle}}
				columns={[
				{ field: 'picture', cellStyle: tableStyle.leftCell, headerStyle: tableStyle.leftCell, render: (rowData) => <Image src={rowData.picture ? rowData.picture : '../../ressources/svgs/TH_Logo.svg'} alt='tool' className={style.SearchAvatar} onError={defaultImage}/>},
				{ field: 'text1', cellStyle: tableStyle.largeCellStyle, headerStyle: tableStyle.largeCellStyle, render: (rowData) =>
					<div>
						<p className={style.searchTitle} >{rowData.text1}</p>
						<p className={style.searchDesc} >{rowData.ordersign}</p>
					</div>
				},
				]}
				data={toolsForUser}
				onRowClick={(event, rowData) => {
					dispatch(getToolDetailFromDB(accessToken, rowData.id));
                    dispatch(setSelectedArticleInStore(rowData.id));
					dispatch(setLastPage('/my-tools'));
					history.push('/detail-view');
				}}
				localization={{
					toolbar: {
						searchPlaceholder: t('myTools:filter'),
					},
				}}
			/>
		</div>
	)
};

export default MyTools;
