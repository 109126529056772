import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';

const notFoundStyles = makeStyles(tools2go => ({
	view: {
		minHeight: '90vh',
		width: '100vw',
		textAlign: 'center',
		marginTop: '-10vh',
		paddingTop: '10vh',
        margin: 0,
        backgroundImage: 'linear-gradient(-45deg, #0D344F, #1F6487)',
	},
    notFoundHeader: {
        color: tools2go.colors.textPrimary,
        marginTop: '20vh',
    },
}));

const Notfound = () => {
	const { t } = useTranslation();
	const style = notFoundStyles();

    return(
        <div className={style.view}>
            <h1 className={style.notFoundHeader}>{t('notFound:notFound')}</h1>
        </div>
    );
}

export default Notfound;
