import React from 'react';
import { Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getToolDetailsFromStore, getToolDetailsError, getToolsForUser } from '../../redux/selectors/toolsSelectors.js';
import { getLastPage } from '../../redux/selectors/generalSelectors.js';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Hero } from '../../ressources/svgs/hero.svg';

const dvStyles = makeStyles(tools2go => ({
    view: {
        minHeight: '90vh',
        textAlign: 'center',
        marginTop: '-10vh',
        paddingTop: '10vh',
    },
    dataNotLoadedSvg: {
		width: '80vw',
		margin: '5vh auto 5vh auto',
		fill: '#FFFFFF',
	},
	dataNotLoadedHeader: {
		width: '80vw',
		margin: '5vh auto 5vh auto',
		color: '#FFFFFF',
		fontWeight: '300',
	},
	dataNotLoadedButton: {
		width: '80vw',
		margin: 'auto',
		fontWeight: '700',
	},
    detailviewInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundImage: 'linear-gradient(180deg, #FFFFFF, #ECECEC)',
        paddingBottom: '5vh',
    },
    detailViewAvatar: {
        width: '250px',
        height: '250px',
        border: '2px solid #0D344F',
        marginBottom: '15px',
    },
    missingImage: {
        textAlign: 'center',
        width: 'auto',
        height: 'auto',
        border: '0px',
        borderColor: '#FFFFFF',
        borderRadius: '0',
        backgroundColor: '#E4E4E4',
        fill: '#747474',
    },
    dvToolTitle: {
        fontSize: '1.5em',
        fontWeight: '700',
        color: '#0D344F',
        margin: '5vh 0vw 0vh 0vw',
    },
    dvToolSite: {
        fontSize: '1em',
        color: '#960043',
        fontWeight: '500',
        margin: '1vh auto',
    },
    callToolOwner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundImage: 'linear-gradient(180deg, #13D8F6, #127D8D)',
        width: '90vw',
        margin: '0vh 5vw',
        height: '10vh',
        color: tools2go.colors.textPrimary,
        fontSize: '1.2em',
        fontWeight: '700',
    },
    detailViewAvailableTool: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '90vw',
        margin: '0vh 5vw',
        height: '10vh',
        color: tools2go.colors.textPrimary,
        background: '#ACB81D',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
        textTransform: 'uppercase',
        fontSize: '1.2em',
    },
    detailViewSvg: {
        fill: '#ffffff',
        display: 'inline',
        verticalAlign: 'middle',
        margin: '1vh 1vw',
    },
    detailViewShadowedBox: {
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
        margin: '5vh auto 0vh auto',
        padding: '5vh 5vw 5vh 5vw',
        width: '80vw',
        minHeight: '40vh',
        textAlign: 'left',
        background: tools2go.colors.bgSecondary,
    },
    dvDivTitle: {
        textAlign: 'center',
        color: '#0D344F',
        fontWeight: 'lighter',
    },
    dvDetail: {
        color: '#323232',
        fontSize: '1em',
        fontWeight: 'lighter',
    },
    detailViewLink: {
        display: 'block',
        color: '#1DA4B8',
        fontWeight: 'lighter',
    },
    detailViewBigButton: {
        display: 'inline-block',
        background: 'linear-gradient(90deg, #1F6487, #0D344F)',
        width: '90vw',
        margin: '5vh 0vw 0vh 0vw',
        color: '#ffffff',
        textTransform: 'uppercase',
        fontSize: '14px',
        fontWeight: '700',
    },
    gmaps: {
        '-webkit-filter': 'grayscale(100%)',
        '-moz-filter': 'grayscale(100%)',
        '-ms-filter': 'grayscale(100%)',
        '-o-filter': 'grayscale(100%)',
        filter: 'grayscale(100%)',
    },
    myDevice: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '90vw',
        margin: '0vh 5vw',
        height: '10vh',
        color: tools2go.colors.textPrimary,
        fontSize: '1.2em',
        fontWeight: '700',
        background: 'linear-gradient(90deg, #1F6487, #0D344F)',
    }
}));

const DetailView = () => {
    const toolDetails = useSelector(getToolDetailsFromStore);
    const toolDetailsError = useSelector(getToolDetailsError);
    const toolsForUser = useSelector(getToolsForUser);

    const lastPage = useSelector(getLastPage);
    const style = dvStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const loadAgain = () => {
        history.push(lastPage);
	}

    const handleClick = () => {
        history.push(lastPage);
    }

    const AvailableTool = () => {
        for (var i = 0; i < toolsForUser.length; i++) {
            if (toolDetails[0].id === toolsForUser[i].id) {
                return(
                    <div className={style.myDevice}>
                        <p>{t('detailView:myDevice')}</p>
                    </div>
                )
            }
        }
        if (toolDetails[0].lastname !== null && toolDetails[0].fistname !== null) {
            return(
                <a href={"tel:"+toolDetails[0].phone}>
                    <div className={style.callToolOwner}>
                        <PhoneForwardedIcon className={style.detailViewSvg}/>
                        <p>{toolDetails[0].firstname + ' ' + toolDetails[0].lastname} {t('detailView:call')}</p>
                    </div>
                </a>
            )
        } else {
            return(
                <div className={style.detailViewAvailableTool}>
                    <p>{t('detailView:toolAvailable')}</p>
                </div>
            )
        }
    }

    const defaultImage = (ev) => {
        ev.target.src = require('../../ressources/svgs/TH_Logo.svg');
        ev.target.className = style.missingImage;
    };

    if (!toolDetails[0]) {
        return(
			<div className={style.view}>
				<Hero className={style.dataNotLoadedSvg} />
    			{toolDetailsError==='' ? (<h3 className={style.dataNotLoadedHeader}>{t('search:loading')}</h3>) : (<h3 className={style.dataNotLoadedHeader}>{t('search:cannotGetData')}: {toolDetailsError.name}</h3>)}
				{toolDetailsError==='' ? (<h3 className={style.dataNotLoadedHeader}>{t('search:pleaseWait')}</h3>) : (<Button className={style.dataNotLoadedButton} color='secondary' variant="contained" onClick={loadAgain}>{t('search:tryAgain')}</Button>)}
    		</div>
        );
    }
    return (
        <div className={style.view}>
            <div className={style.detailviewInfo}>
                <Avatar alt="Tool Image" src={!!toolDetails[0].picture ? toolDetails[0].picture : '../../ressources/svgs/TH_Logo.svg'} className={style.detailViewAvatar} onError={defaultImage}/>
                <div>
                    <h3 className={style.dvToolTitle} >{toolDetails[0].text1}</h3>
                    <h5 className={style.dvDetail}>{t('detailView:toolNumber')}: {toolDetails[0].tool_number}</h5>
                    <h5 className={style.dvDetail}>{t('detailView:toolSerialNumber')}: {toolDetails[0].serial_number}</h5>
                    <h5 className={style.dvToolSite} >{`${t('detailView:costCenter')}: ${toolDetails[0].costlocation || t('detailView:noCostCenter')}`}</h5>
                </div>
                <AvailableTool />
                <div className={style.detailViewShadowedBox}>
                    <h3 className={style.dvDivTitle}>{t('detailView:toolLocation')}</h3>
                    <iframe className={style.gmaps}title='gmaps' style={{width: "100%"}} src={ `https://maps.google.com/maps?q=${toolDetails[0].costlocation}&output=embed` } frameBorder="0" scrolling="no"/>
                    <p className={style.dvDetail}>{t('sites:site')}: {toolDetails[0].name}</p>
                    <p className={style.dvDetail}>{t('sites:city')}: {toolDetails[0].number}</p>
                    <p className={style.dvDetail}>{(toolDetails[0].lastname !== null && toolDetails[0].fistname !== null) ? `${t('detailView:costCenterAddress')}: ${toolDetails[0].costlocation || t('detailView:noCostCenter')}` : `${t('sites:address')}:`} {toolDetails[0].location}</p>
                </div>
                <br />
                {
                <div className={style.detailViewShadowedBox}>
                    <h3 className={style.dvDivTitle}>{t('detailView:techInfo')}</h3>
                    <p className={style.dvDetail}>{toolDetails[0].dimension}</p>
                    <p className={style.dvDetail}>{toolDetails[0].power1}</p>
                    <p className={style.dvDetail}>{toolDetails[0].power2}</p>
                    <p className={style.dvDetail}>{toolDetails[0].power3}</p>
                    <br />
                    <p className={style.dvDetail}><strong>{t('detailView:documents')}:</strong></p>

                    <a href={toolDetails[0].help_url} target="_blank" rel="noopener noreferrer">{toolDetails[0].help_url}</a>
                </div>

                }
                <Button className={style.detailViewBigButton} onClick={handleClick}>{t('detailView:closeView')}</Button>
            </div>
        </div>
    )
};

export default DetailView;
