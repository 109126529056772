import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

const tools2go = createMuiTheme({
    colors: {
        bgPrimary: '#0E344F',
        bgSecondary: '#FFFFFF',
        textPrimary: '#FFFFFF',
        textSecondary: '#0E344F',
        grey: '#ECECEC',
        landingPageButtonBg: '#0E344F',
    },
    overrides: {
        Mui: {
            root: {
                '&$focused': {
                    borderColor: '#0E344F',
                },
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
            },
            root: {
                width: '100vw',
                height: '10vh',
                boxShadow: 'none',
                color: '#FFFFFF',
                position: 'absolute',
                top: '0',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        MuiToolbar: {
            regular: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '0',
                padding: '0',
            },
        },
        MuiIconButton: {
            root: {
                padding: '0px',
                width: 'auto',
                margin: '0px',
                textAlign: 'center',
                color: '#FFFFFF',
            },
            edgeStart: {
                margin: '0px',
            },
            label: {
                width: '6vh',
                margin: 'auto',
                borderRadius: '0px',
                overflow: 'vizible',
            },
        },
        MuiIcon: {
            root: {
                color: 'rgba(0, 0, 0, 0.87)',
            },
        },
        MuiTypography: {
            h6: {
                width: '60vw',
                margin: 'auto',
                textTransform: "uppercase",
                textAlign: 'center',
                verticalAlign: 'middle',
                fontSize: '2vh',
                fontWeight: '700',
            },
        },
        MuiFab: {
            root: {
                width: '30px',
                height: '30px',
                margin: '0',
                float: 'right',
                boxShadow: '0 5px 10px 0 rgba(0,0,0,0.20)',
                borderRadius: '15px',
                color: '#FFFFFF',
                minHeight: '0',
            },
        },
        MuiFormControl: {
            root: {
                background: '#FFFFFF',
                width: '80vw',
                margin: '0vh 10vw 0vh 10vw',
                borderRadius: '2px',
            },
            marginNormal: {
                marginTop: '0px',
                marginBottom: '0px',
            },
        },
        MuiButton: {
            root:{
            	border: 0,
            	borderRadius: '2px',
            	height: 50,
        		width: 'auto',
        		margin: '2vh 10vw 0vh 10vw',
        		textTransform: 'none',
        		fontWeight: 700,
            },
            containedSecondary: {
        		color: '#0E344F',
        		backgroundColor: '#FFFFFF',
                "&:hover": {
                    backgroundColor: '#0E344F',
                    color: '#FFFFFF',
                },
            },
        },
        MuiAvatar: {
            root: {
                width: '90px',
                height: '90px',
                verticalAlign: 'middle',
                top: '5vh',
            },
        },
        MuiTableFooter: {
            root: {
                display: 'none',
            },
        },
        MuiSvgIcon: {
            root: {
                width: '1.3em',
                height: '1.3em',
            },
        },
        MuiTablePagination: {
            toolbar: {
                maxWidth: '100vw',
            },
        },
        MuiTableHead: {
            root: {
                display: 'none',
            },
        },
    },
});

const tableStyle = {
    leftCell: {
        minWidth: '20vw',
        maxWidth: '20vw',
        width: '20vw',
        padding: '1vh 0vw 1vh 5vw',
        textAlign: 'left',
    },
    rightCell: {
        minWidth: '20vw',
        maxWidth: '20vw',
        width: '20vw',
        padding: '1vh 5vw 1vh 0vw',
        textAlign: 'right',
    },
    largeCellStyle: {
        minWidth: '45vw',
        maxWidth: '45vw',
        width: '45vw',
        padding: '1vh 1vw',
        textAlign: 'left',
    },
    searchFieldStyle: {
        maxWidth: '90%',
        color: 'textPrimary'
    },
};

const useStyles = makeStyles(tools2go => ({
    '@global': {
        '.MuiButton-containedSecondary:hover': {
            backgroundColor: tools2go.colors.bgPrimary,
            color: tools2go.colors.textPrimary
        },
        '.MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid #0E344F',
        },
        '.MuiInput-underline:after': {
            borderBottom: '2px solid #0E344F',
        },
        //--------------------------LOGIN PAGE--------------------------
        //--------------------------LANDING PAGE--------------------------
        //--------------------------APP BAR HEADER--------------------------
        //--------------------------MAINMENU--------------------------
        '#mainMenu': {
            height: '90vh',
        },
        '#drawerHeader': {
            backgroundImage: 'linear-gradient(-90deg, #0D344F, #1F6487)',
            padding: '3vh 5vw 0vh 5vw',
        },
        '#drawer': {
            width: '80vw',
        },
        '#drawerContentName': {
            padding: '10vh 0vw 3vh 0vw',
            color: tools2go.colors.textPrimary,
            fontSize: '2.5vh',
            fontWeight: 'lighter',
        },
        //--------------------------SEARCH PAGE--------------------------
        //--------------------------MY TOOLS PAGE--------------------------
        //--------------------------DETAIL VIEW PAGE--------------------------
        //--------------------------SITES PAGE--------------------------
        //--------------------------SPECIFIC SITE PAGE--------------------------
    //--------------------------GLOBAL STYLING--------------------------
    },
    //--------------------------LOGIN PAGE--------------------------
    //--------------------------FOOTER--------------------------
    //--------------------------LANDING PAGE--------------------------
    //--------------------------MAINMENU--------------------------
    //--------------------------SEARCH PAGE--------------------------
    //--------------------------MY TOOLS PAGE--------------------------
    //--------------------------DETAIL VIEW PAGE--------------------------
    //--------------------------SITES VIEW PAGE--------------------------
    //--------------------------SPECIFIC SITE PAGE--------------------------
}));

export { useStyles, tools2go, tableStyle };
