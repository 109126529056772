import { GET_SPECIFIC_SITE, SET_SPECIFIC_SITE } from '../actionTypes';

const initialState = {
    specificSite: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SPECIFIC_SITE: {
            return state;
        }

        case SET_SPECIFIC_SITE: {
			return {
				...state,
				specificSite: action.payload
			};
		}

        default:
            return state;
    }
}
