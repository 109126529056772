import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './locales/en.json';
import de from './locales/de.json';

const resources = {
    en: en,
    de: de
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "de",

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
