import {
	LOGOUT,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGIN_SESSION,
	LOGIN_SESSION_ERROR,
	CHANGE_USERNAME,
	CHANGE_PASSWORD,
	CHANGE_USERNAME_AND_PASSWORD
} from '../actionTypes';
import API from '../../api/api'
import {
	getLocationsFromDB,
	getMyToolsFromDB,
} from './toolsActions';

const api = new API();

export const login = (username, password) => {

	return dispatch => api.login(username, password)
		//THIS WILL BE REPLACED BY THE USERNAME AND PASSWORD VARIABLES. USING FIXED USERNAME AND PASSWORD FOR DEV.
		.then(res => {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: {
					username: username,
					password: password,
					accessToken: res.accessToken,
					customerId: res.customer_id,
					userID: res.id,
				}
			});
			dispatch(getLocationsFromDB(res.accessToken));
			dispatch(getMyToolsFromDB(res.accessToken, res.id));
		}).catch(err => {
			dispatch({
				type: LOGIN_ERROR,
				payload: err
			});
		})
}

export const loginSession = () => {
	return(
	//CHECK IF BACKEND DISCONNECTED SESSION
	{
		type: LOGIN_SESSION
	}
	)
};

export const loginSessionError = () => {
	return({
		type: LOGIN_SESSION_ERROR,
		payload: 'Login Session Expired, please log in again.'
	})
};

export const logout = () => {
	return({
		type: LOGOUT
	})
};

export const changeCredentials = (username, password) => {
	if (!!username && !!password) {
		return({
			type: CHANGE_USERNAME_AND_PASSWORD,
			payload: {
				username: username,
				password: password
			}
		});
	} else {
		if (!!username) {
			return({
				type: CHANGE_USERNAME,
				payload: {
					username: username
				}
			});
		}
		if (!!password) {
			return({
				type: CHANGE_PASSWORD,
				payload: {
					password: password
				}
			});
		}
	}
}
