import { SET_LAST_PAGE, SEARCH_IN_DB, ERROR_SEARCH_IN_DB } from '../actionTypes';
import API from '../../api/api'

const api = new API();

export const setLastPage = (page) => {
    return({
        type: SET_LAST_PAGE,
        payload: page
    });
}

export const setSearchError = (error) => {
    return({
        type: ERROR_SEARCH_IN_DB,
        payload: error
    });
}

export const search = (accessToken, searchTerm) => {
    return dispatch => api.searchInDB(accessToken, searchTerm)
        .then(res => {
            dispatch({
                type: SEARCH_IN_DB,
                payload: res
            })
        }).catch (err => {
            dispatch({
                type: ERROR_SEARCH_IN_DB,
                payload: err
            })
        })
}
