import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import { getAllAvailableTools, getAllAvailableToolsError } from '../../redux/selectors/toolsSelectors.js';
import { useHistory } from 'react-router-dom';
import { setSelectedArticleInStore, getToolDetailFromDB, getAllAvailableToolsFromDB } from '../../redux/actions/toolsActions.js';
import { getAccessTokenFromStore } from '../../redux/selectors/accountSelectors.js';
import { setLastPage } from '../../redux/actions/generalActions.js';
import { tableStyle } from '../Theme/Theme.js';
import Image from "react-graceful-image";
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Hero } from '../../ressources/svgs/hero.svg';
import { useTranslation } from "react-i18next";
import { Button } from '@material-ui/core';


const allAvailableToolsStyles = makeStyles(tools2go => ({
	view: {
        minHeight: '90vh',
        width: '100vw',
        textAlign: 'center',
        marginTop: '-10vh',
        paddingTop: '10vh',
    },
	dataNotLoadedSvg: {
		width: '80vw',
		margin: '5vh auto 5vh auto',
		fill: '#FFFFFF',
	},
	dataNotLoadedHeader: {
		width: '80vw',
		margin: '5vh auto 5vh auto',
		color: '#FFFFFF',
		fontWeight: '300',
	},
	dataNotLoadedButton: {
		width: '80vw',
		margin: 'auto',
		fontWeight: '700',
	},
	SearchAvatar: {
        textAlign: 'center',
        maxWidth: '10vh',
        width: 'auto',
        maxHeight: '7vh',
        height: 'auto',
        border: '0px',
        borderColor: '#FFFFFF',
		borderRadius: '0',
    },
	missingImage: {
		textAlign: 'center',
        maxWidth: '10vh',
        width: 'auto',
        maxHeight: '7vh',
        height: 'auto',
        border: '0px',
        borderColor: '#FFFFFF',
		borderRadius: '0',
		backgroundColor: '#E4E4E4',
		fill: '#747474',
	},
    searchTitle: {
        color: '#0D344F',
        fontSize: '1rem',
    },
    searchDesc: {
        color: '#878787',
    },
    inStock: {
        background: 'linear-gradient(127deg, #1F6487 0%, #0D344F 82%)',
    },
    outOfStock: {
        background: 'linear-gradient(127deg, #FF0038 0%, #78001C 82%)',
    },
}));

const AvailableTools = () => {
	const allAvailableTools = useSelector(getAllAvailableTools);
	const accessToken = useSelector(getAccessTokenFromStore);
	const allAvailableToolsError = useSelector(getAllAvailableToolsError);
	const history = useHistory();
	const dispatch = useDispatch();
	const style = allAvailableToolsStyles();
	const { t } = useTranslation();

	const defaultImage = (ev) => {
		ev.target.src = require('../../ressources/svgs/TH_Logo.svg');
		ev.target.className = style.missingImage;
	};

	const loadAgain = () => {
		dispatch(getAllAvailableToolsFromDB(accessToken));
	}

	if (allAvailableTools === '') {
		return(
			<div className={style.view}>
				<Hero className={style.dataNotLoadedSvg} />
    			{allAvailableToolsError==='' ? (<h3 className={style.dataNotLoadedHeader}>{t('search:loading')}</h3>) : (<h3 className={style.dataNotLoadedHeader}>{t('search:cannotGetData')}: {allAvailableToolsError.name}</h3>)}
				{allAvailableToolsError==='' ? (<h3 className={style.dataNotLoadedHeader}>{t('search:pleaseWait')}</h3>) : (<Button className={style.dataNotLoadedButton} color='secondary' variant="contained" onClick={loadAgain}>{t('search:tryAgain')}</Button>)}
    		</div>
        );
    }

	return (
		<div>
			<MaterialTable
				title=''
				options={{search: false, paging: false}}
				columns={[
				{ field: 'picture', cellStyle: tableStyle.leftCell, headerStyle: tableStyle.leftCell, render: (rowData) => <Image src={rowData.picture ? rowData.picture : '../../ressources/svgs/TH_Logo.svg'} alt='tool' className={style.SearchAvatar} onError={defaultImage}/>},
				{ field: 'text1', cellStyle: tableStyle.largeCellStyle, headerStyle: tableStyle.largeCellStyle, render: (rowData) =>
					<div>
						<p className={style.searchTitle} >{rowData.text1}</p>
						<p className={style.searchDesc} >{rowData.ordersign}</p>
					</div>
				},
				]}
				data={allAvailableTools}
				onRowClick={(event, rowData) => {
					dispatch(getToolDetailFromDB(accessToken, rowData.id));
                    dispatch(setSelectedArticleInStore(rowData.id));
					dispatch(setLastPage('/available-tools'));
					history.push('/detail-view');
				}}
			/>
		</div>
	)
};

export default AvailableTools;
