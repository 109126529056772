import React from 'react';
import { tableStyle } from '../Theme/Theme.js';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import { Fab, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getSpecificSiteFromStore } from '../../redux/selectors/sitesSelectors.js';
import { getArticles, getTransactionInfoFromStore, getToolsInLocationFromStore, getToolsInLocationErrorFromStore } from '../../redux/selectors/toolsSelectors.js';
import { setSelectedArticleInStore, getAllArticlesFromDb, setArticlesError, getToolDetailFromDB } from '../../redux/actions/toolsActions.js';
import { getAccessTokenFromStore } from '../../redux/selectors/accountSelectors.js';
import { setLastPage } from '../../redux/actions/generalActions.js';
import { useTranslation } from "react-i18next";
import Image from "react-graceful-image";
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Hero } from '../../ressources/svgs/hero.svg';

const specificSiteStyles = makeStyles(tools2go => ({
	view: {
        minHeight: '90vh',
        width: '100vw',
        textAlign: 'center',
        marginTop: '-10vh',
        paddingTop: '10vh',
    },
	dataNotLoadedSvg: {
		width: '80vw',
		margin: '5vh auto 5vh auto',
		fill: '#FFFFFF',
	},
	dataNotLoadedHeader: {
		width: '80vw',
		margin: '5vh auto 5vh auto',
		color: '#FFFFFF',
		fontWeight: '300',
	},
	dataNotLoadedButton: {
		width: '80vw',
		margin: 'auto',
		fontWeight: '700',
	},
	SearchAvatar: {
        textAlign: 'center',
        maxWidth: '10vh',
        width: 'auto',
        maxHeight: '7vh',
        height: 'auto',
        border: '0px',
        borderColor: '#FFFFFF',
		borderRadius: '0',
    },
	missingImage: {
		textAlign: 'center',
        maxWidth: '10vh',
        width: 'auto',
        maxHeight: '7vh',
        height: 'auto',
        border: '0px',
        borderColor: '#FFFFFF',
		borderRadius: '0',
		backgroundColor: '#E4E4E4',
		fill: '#747474',
	},
    searchTitle: {
        color: '#0D344F',
        fontSize: '1rem',
    },
    searchDesc: {
        color: '#878787',
    },
	specificSiteView: {
		marginTop: '9vh',
		height: '91vh',
	},
	inStock: {
        background: 'linear-gradient(127deg, #C8D720 0%, #9FAB15 82%)',
    },
    outOfStock: {
        background: 'linear-gradient(127deg, #FF0038 0%, #78001C 82%)',
    },
}));

const SpecificSite = () => {
	const specificSite = useSelector(getSpecificSiteFromStore);
	const toolsInLocation = useSelector(getToolsInLocationFromStore);
	const toolsInLocationError = useSelector(getToolsInLocationErrorFromStore);
	const transactionsInfo = useSelector(getTransactionInfoFromStore);
    let articles = useSelector(getArticles);
	const accessToken = useSelector(getAccessTokenFromStore);
	const style = specificSiteStyles();
	const history = useHistory();
    const dispatch = useDispatch();
	const { t } = useTranslation();
	let showFilter = false;

	let articlesToDisplay = [];
	let articleData = [];

	if (specificSite === '') {
		history.push('/sites');
	}

	const loadAgain = () => {
		dispatch(setArticlesError());
		dispatch(getAllArticlesFromDb(accessToken));
	}

	if (!toolsInLocation) {
        return(
			<div className={style.view}>
				<Hero className={style.dataNotLoadedSvg} />
    			{toolsInLocationError==='' ? (<h3 className={style.dataNotLoadedHeader}>{t('search:loading')}</h3>) : (<h3 className={style.dataNotLoadedHeader}>{t('search:cannotGetData')}: {toolsInLocationError.name}</h3>)}
				{toolsInLocationError==='' ? (<h3 className={style.dataNotLoadedHeader}>{t('search:pleaseWait')}</h3>) : (<Button className={style.dataNotLoadedButton} color='secondary' variant="contained" onClick={loadAgain}>{t('search:tryAgain')}</Button>)}
    		</div>
        );
    } else {
		if (toolsInLocation.length > 1) {
			showFilter = true;
		} else {
			showFilter = false;
		}
	}

	for (let i = 0; i < transactionsInfo.length; i++) {
		articlesToDisplay.push(transactionsInfo[i].container_number === specificSite ? transactionsInfo[i].article_id : null);
	}

	articlesToDisplay = [...new Set(articlesToDisplay)];

	for (let i = 0; i < articlesToDisplay.length; i++) {
		articles.map((article) => {
			if (article.id === articlesToDisplay[i]) {
				articleData.push(article);
			}
			return null;
		});
	}

	const defaultImage = (ev) => {
		ev.target.src = require('../../ressources/svgs/TH_Logo.svg');
		ev.target.className = style.missingImage;
	};

	return (
		<div className={style.view}>
            <MaterialTable
                title=''
                options={{search: showFilter, paging: false, searchFieldStyle:tableStyle.searchFieldStyle}}
				columns={[
				{ field: 'picture', cellStyle: tableStyle.leftCell, headerStyle: tableStyle.leftCell, render: (rowData) => <Image src={rowData.picture ? rowData.picture : '../../ressources/svgs/TH_Logo.svg'} alt='tool' className={style.SearchAvatar} onError={defaultImage}/>},
				{ field: 'text1', cellStyle: tableStyle.largeCellStyle, headerStyle: tableStyle.largeCellStyle, render: (rowData) =>
					<div>
						<p className={style.searchTitle} >{rowData.text1}</p>
						<p className={style.searchDesc} >{rowData.ordersign}</p>
					</div>
				},
				{ field: 'package_count', cellStyle: tableStyle.rightCell, headerStyle: tableStyle.rightCell, render: (rowData) =>
					<Fab className={rowData.count>0 ? style.inStock : style.outOfStock}>{rowData.count}</Fab>
				},
				]}
                data={toolsInLocation}
                onRowClick={(event, rowData) => {
					dispatch(getToolDetailFromDB(accessToken, rowData.id));
                    dispatch(setSelectedArticleInStore(rowData.id));
                    dispatch(setLastPage('/specific-site'));
                    history.push('/detail-view');
                }}
				localization={{
					toolbar: {
						searchPlaceholder: t('specificSite:filter'),
					},
				}}
            />
		</div>
	)
};

export default SpecificSite;
