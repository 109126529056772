import React from 'react';
import { ReactComponent as Hero } from '../../ressources/svgs/TH_Logo.svg';
import { makeStyles } from '@material-ui/core/styles';

const footerStyles = makeStyles(tools2go => ({
	footer: {
		display: 'block',
		textAlign: 'center',
		position: 'absolute',
		bottom: '0',
		width: '100vw',
		marginBottom: '2vh',
		overflow: 'hidden',
	},
	toolHeroLogo: {
		width: '10vh',
		height: '10vh',
		margin: '0px auto',
		fill: '#858585',
	},
	footerText: {
		color: '#858585',
		margin: '0px auto',
	},
}));

const Footer = () => {
	const style = footerStyles();

	return (
    	<div id='footer' className={style.footer}>
    		<Hero className={style.toolHeroLogo} />
			<p className={style.footerText}>Tool Hero 2.0</p>
    	</div>
	)
};

export default Footer;
